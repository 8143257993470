<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农场管理</el-breadcrumb-item>
      <el-breadcrumb-item>产量预估</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card shadow="hover" class="card-sty" body-style="height:100%;">
      <div>
        <!-- 搜索 -->
        年份:
        <el-date-picker
          v-model="selectYear"
          @change="handleSearch"
          type="year"
          size="small"
          value-format="yyyy"
          placeholder="选择年"
        >
        </el-date-picker>
        <!--a-month-picker placeholder="Select month"  />
          <a-input
              v-model="selectYear"
              placeholder="请输入标题"
              style="width: 200px"
              allowClear
          />-->
        <el-button @click="handleSearch" style="margin-left: 10px" size="small">
          搜索
        </el-button>
      </div>
      <!--<a-skeleton :loading="loading" active  :paragraph="{ rows: 8 }">-->
      <el-table
        header-row-class-name="my-table-head-style"
        class="table_class"
        v-loading="spinning"
        :data="data"
        border
        :summary-method="getSummaries"
        show-summary
        @cell-click="cellEdit"
        style="width: 100%"
      >
        <el-table-column
          fixed
          prop="plantVarietyName.value"
          label="品种"
          :render-header="handleRenderHead"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="january"
          :label="selectYear + '-01'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.january.edit"
              v-model="scope.row.january.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 1, 'january')"
            ></el-input>
            <span v-else>{{ scope.row.january.value ? scope.row.january.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="february"
          :label="selectYear + '-02'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.february.edit"
              v-model="scope.row.february.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 2, 'february')"
            ></el-input>
            <span v-else>{{ scope.row.february.value ? scope.row.february.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="march"
          :label="selectYear + '-03'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.march.edit"
              v-model="scope.row.march.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 3, 'march')"
            ></el-input>
            <span v-else>{{ scope.row.march.value ? scope.row.march.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="april"
          :label="selectYear + '-04'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.april.edit"
              v-model="scope.row.april.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 4, 'april')"
            ></el-input>
            <span v-else>{{ scope.row.april.value ? scope.row.april.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="may"
          :label="selectYear + '-05'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.may.edit"
              v-model="scope.row.may.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 5, 'may')"
            ></el-input>
            <span v-else>{{ scope.row.may.value ? scope.row.may.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="june"
          :label="selectYear + '-06'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.june.edit"
              v-model="scope.row.june.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 6, 'june')"
            ></el-input>
            <span v-else>{{ scope.row.june.value ? scope.row.june.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="july"
          :label="selectYear + '-07'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.july.edit"
              v-model="scope.row.july.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 7, 'july')"
            ></el-input>
            <span v-else>{{ scope.row.july.value ? scope.row.july.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="august"
          :label="selectYear + '-08'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.august.edit"
              v-model="scope.row.august.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 8, 'august')"
            ></el-input>
            <span v-else>{{ scope.row.august.value ? scope.row.august.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="september"
          :label="selectYear + '-09'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.september.edit"
              v-model="scope.row.september.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 9, 'september')"
            ></el-input>
            <span v-else>{{ scope.row.september.value ? scope.row.september.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="october"
          :label="selectYear + '-10'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.october.edit"
              v-model="scope.row.october.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 10, 'october')"
            ></el-input>
            <span v-else>{{ scope.row.october.value ? scope.row.october.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="november"
          :label="selectYear + '-11'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.november.edit"
              v-model="scope.row.november.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 11, 'november')"
            ></el-input>
            <span v-else>{{ scope.row.november.value ? scope.row.november.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          prop="december"
          :label="selectYear + '-12'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.december.edit"
              v-model="scope.row.december.value"
              placeholder=""
              v-focus
              @blur.native.capture="tableBlur(scope.row, 12, 'december')"
            ></el-input>
            <span v-else>{{ scope.row.december.value ? scope.row.december.value + 'kg' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="right"
          fixed="right"
          prop="totle"
          label="总计(kg)"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.totle.value + 'kg' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!--</a-skeleton>-->
    </el-card>
  </div>
</template>

<script>
export default {
  name: "PlantYieldPredict",
  data() {
    return {
      rows: 8,
      loading: true,
      selectYear: this.moment(new Date()).format("YYYY"),
      spinning: true,
      data: [],
    };
  },
  directives: {
    //注册一个局部的自定义指令 v-focus
    focus: {
      inserted: function (el) {
        // 聚焦元素
        el.querySelector("input").focus();
      },
    },
  },
  watch: {
    selectYear: function (val) {
      if (val === null || val === "") {
        this.$message.success("正在查询今年预估产量");
        this.selectYear = this.moment(new Date()).format("YYYY");
        this.handleSearch();
      }
    },
  },
  created() {
    this.getPlantYieldPredictTableDataByYear();
    this.setLoading();
  },
  methods: {
    setLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
          return;
        }
        const values = data.map((item) => Number(item[column.property].value));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "N/A";
        }
      });
      return sums;
    },
    tableBlur(data, month, column) {
      this.reset();
      let editData = {
        year: this.selectYear,
        month: month,
        plantVarietyId: data.plantVarietyId.value,
        predict: data[column].value,
      };
      this.http.plantYieldPredict
        .updatePlantYieldPredict(editData)
        .then((response) => {
          if (response.data.status == 200) {
            this.$message.success(response.data.msg);
          } else {
            this.$message.warning(response.data.msg);
          }
          this.getPlantYieldPredictTableDataByYear();
        });
    },
    toEdit() {},
    reset() {
      this.data.forEach((item, index) => {
        for (let i in item) {
          item[i].edit = false;
        }
      });
    },
    cellEdit(row, column, cell, event) {
        let flag = this.authVerify.verify('plantYieldPredict-edit');
      this.reset();
      if (row[column.property] && flag) {
        row[column.property].edit = true;
        /*setTimeout(() => {
                        this.$refs[column.property].querySelector('input').focus();
                    }, 20);*/
      }
    },
    getPlantYieldPredictTableDataByYear() {
      this.http.plantYieldPredict
        .getPlantYieldPredictTableDataByYear({
          year: this.selectYear,
        })
        .then((response) => {
          if (response.data.status == 200) {
            let data = response.data.data;
            data.forEach((item, index) => {
              for (let i in item) {
                item[i] = {
                  value: item[i],
                  edit: false,
                };
              }
            });
            /*data.forEach(row => {
                            // 是否标记
                            row['isEdit'] = false;
                            // 是否新增
                            row['isAdd'] = false
                        });*/
            console.log(data);
            this.data = data;
          }
          this.spinning = false;
        });
    },
    handleSearch() {
      this.spinning = true;
      console.log(this.selectYear);
      this.getPlantYieldPredictTableDataByYear();
    },
    handleRenderHead(h, { column, $index }) {
      return h("div", {
        attrs: {
          class: "new-cell", //ele原来样式
        },
        domProps: {
          innerHTML: `
            <div class="new-label">
              <div class="label-col">品种</div>
              <div class="label-row">时间</div>
            </div>
          `,
        },
      });
    },
  },
};
</script>

<style scoped>
.table_class >>> input {
  border: 0px solid #dcdfe6;
  height: 23px;
}

.el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
  white-space: nowrap;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  /*width: 8px;*/ /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: rgba(143, 143, 143, 0.8);
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
/deep/ .my-table-head-style th:first-child{
  position: relative !important;
}
/deep/ .my-table-head-style th:first-child .cell{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important;
}
/deep/ .new-cell{
  width: 100%;
  height: 100%;
  .new-label{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    position: relative;
    overflow: hidden;
      background-image: linear-gradient(to top right, #fff 50%, #f5f5f5 50%);
    // .label-col::before{
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-image: linear-gradient(to top right, #fff 50%, #f5f5f5 50%);
    //   content: ' ';
    // }
    .label-col{
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    }
    .label-row{
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 5px;
    }
  }
}
</style>
